<template>

  <div>

    <div class="control" :class="disabled && 'disabled'">

      <!-- Label is displayed in case of input value -->
      <label
        class="is-size-7 has-text-grey"
        :class="focused && 'active'"
        :for="'custom-input' + id"
        v-show="!!value"
      >{{ label }}</label>

      <!-- If input is 'hideable', type can be toggled to 'password' -->
      <input
        :id="'custom-input' + id"
        class="input"
        :class="focused && 'active'"
        :disabled="disabled"
        :type="hidden ? 'password' : type"
        :placeholder="placeholder"
        v-model="value"
        @blur="focused = false"
        @focus="focused = true"
        @input="onInput"
        :maxlength="limit ? limit : 99"
      >

      <!-- Inline style to pass background `url` reference dynamically -->
      <span
        v-if="hideable"
        :style="{ background: '' +
          `url(/static/images/auth_eye_${
            hidden ? 'passive' : 'active'
          }.svg) no-repeat center`
        }"
        @click="toggleHidden()"
      ></span>
    </div>
    <!-- Validation messages -->
      <p
        v-if="message"
        class="
          message
          is-size-7
          has-text-left
          has-text-weight-medium
        "
      >
        <span>{{ message }}</span>
      </p>
  </div>

</template>

<script>
export default {
  /**
   * Hideable: if value can be toggled hidden/unhidden
   * Label: the copy for the associated label
   * Placeholder: the copy for the associated placeholder
   * Type: the type of the input - set 'text' + 'hideable: true' for passwords
   * Initial: any default value for the input
   */
  props: {
    limit: Number,
    label: String,
    type: String,
    initial: String,
    message: String,
    disabled: Boolean,
    hideable: Boolean,
    placeholder: String
  },

  data () {
    return {
      /**
       * Whether <input> is in a focused state
       */
      focused: false,

      /**
       * Whether <input> value is in hidden state
       */
      hidden: this.hideable,

      /**
       * Random id to bind <input> and <label>
       */
      id: Math.random(),

      /**
       * Current value for <input>
       */
      value: this.initial || ''
    }
  },

  methods: {
    /**
     * Handle a user input event
     */
    onInput () {
      return this.$emit('input', this.value)
    },

    /**
     * Toggle the visibility state
     */
    toggleHidden () {
      this.hidden = !this.hidden
    }
  }
}
</script>

<style lang="sass" scoped>

div.control
  & > input
    border-color: #D8D8D8
    color: #363636
    height: 3.2rem
    margin: 0.4rem 0
    padding-left: calc(1.4rem - 1px)

    &.active
      border-color: #FF6441

    &::placeholder
      color: #363636
      opacity: 0.5

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active
      -webkit-box-shadow: 0 0 0 30px white inset !important

  & > label:not(.checkbox)
    background: linear-gradient(to top, #FFFFFF 0%, #FFFFFF 60%, rgba(255, 255, 255, 0) 61%, rgba(255, 255, 255, 0) 100%)
    margin: 0 0.8rem
    padding: 0 0.2rem
    position: absolute
    line-height: 1.25
    z-index: 1

    &.active
      color: #FF6441 !important

  &.disabled
    > label:not(.checkbox)
      background: linear-gradient(to top, #FBF7F7 0%, #FBF7F7 60%, rgba(255, 255, 255, 0) 61%, rgba(255, 255, 255, 0) 100%)

  & > span
    width: 20px
    height: 20px
    display: block
    padding: 1.6rem
    position: absolute
    top: 0.4rem
    right: 0px
    cursor: pointer

p.message
  color: #FF3223
  line-height: 0.8rem
  margin-bottom: 0.2rem

  & > span::before
    content: ''
    width: 16px
    height: 8px
    margin-left: 0.2rem
    display: inline-block
    background: url(/static/images/auth_error.svg) no-repeat left bottom

</style>
