<template>

  <div>

    <auth-input
      :hideable="false"
      :label="'Your Email'"
      :placeholder="'Email'"
      :type="'text'"
      :initial="initial || ''"
      v-on:input="onInput($event)"
      :disabled="disabled"
    ></auth-input>

  </div>

</template>

<script>
import AuthInput from './AuthInput'

export default {
  components: { AuthInput },
  props: {
    initial: String,
    disabled: Boolean
  },
  methods: {
    /**
     * Test input validity
     */
    isValid (value) {
      return !!value && /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)
    },

    /**
     * Handle the input event
     */
    onInput (e) {
      this.$emit('input', e)
      // return ony if valid
      this.$emit('valid', this.isValid(e))
    }
  }
}
</script>

<style lang="sass" scoped>

p.message
  color: #FF3223
  background: transparent
  height: 0.4rem
  line-height: 1rem
  margin-bottom: 0.2rem

  & > span::before
    content: ''
    width: 16px
    height: 8px
    margin-left: 0.2rem
    display: inline-block
    background: url(/static/images/auth_error.svg) no-repeat left bottom

</style>
