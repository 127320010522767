<template>
  <!-- On Submit Validation Messages -->
  <p
    class="
      message
      is-size-7
      has-text-left
      has-text-weight-medium
    "
    :class="!error && 'is-invisible'"
  >
    <!-- The validation message -->
    <span>
      {{ error }}
    </span>
  </p>
</template>

<script>
export default {
  props: {
    error: String
  }
}
</script>

<style lang="sass" scoped>
p:not(.message)
  height: 1.4rem !important

  & a
    text-decoration: underline

p.message
  color: #FF3223
  background: transparent
  height: 0.2rem
  line-height: 1.5rem
  margin-bottom: 1.2rem

  & > span::before
    content: ''
    width: 16px
    height: 8px
    margin-left: 0.2rem
    display: inline-block
    background: url(/static/images/auth_error.svg) no-repeat left bottom
</style>
