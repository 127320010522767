<template>

  <div class="control">

    <label
      class="checkbox is-size-7 has-text-grey-dark"
      @click="toggle()"
    >
      <div class="check" :class="active && 'active'"></div>
      <span>{{ label }}</span>
    </label>

  </div>

</template>

<script>
export default {
  props: {
    label: String
  },

  data: () => ({
    active: false
  }),

  methods: {
    toggle () {
      this.$emit('change', this.active = !this.active)
    }
  }
}
</script>

<style lang="sass" scoped>

.control
  height: 1.4rem

label
  margin: 0.4rem 0.1rem

  & > span
    position: absolute
    top: 1px
    min-width: 100px
    margin-left: 1.4rem

  & > div.check
    height: 15px
    width: 15px
    border: 1px solid #363636
    border-radius: 2px

    &.active
      border: 0
      background: url(/static/images/auth_check_box.svg)

</style>
