<template>
  <div>
    <!-- Success message! -->
    <div
      v-if="!shown"
      class="auth-flash-message-container"
    >
      <div
        class="auth-flash-message has-background-white"
        v-for="message in customMessages"
        :class="message.level"
        :key="message.id"
      >
        <div
          class="nb-icon"
        >
          <i class="fas fa-check has-text-success" v-if="message.level === 'success'"></i>
          <i class="fas fa-exclamation-triangle has-text-danger" v-if="message.level === 'danger'"></i>
        </div>

        <div
          class="nb-message"
        >
          {{ message.text }}
          <span>
            <a href="mailto:membership@joinpouch.com" v-if="message.level === 'danger'">
              contact us.
            </a>
          </span>
        </div>
      </div>
    </div>

    <div class="form-block">
      <div class="form-block__wrapper">
        <!-- Main Heading for Flow -->
        <h1 class="is-size-3 has-text-weight-black has-text-centered">
          Log in to <span class="has-text-primary">Pouch</span>
        </h1>
        <div>
          <!-- The Credentials Form for Login Flow -->
          <form @submit.prevent="handleSubmit">

            <!-- Email Field -->
            <email-field
              v-on:valid="validEmail = $event"
              v-on:input="emailValue = $event"
            ></email-field>

            <!-- Password Field: No Validation UI & No Confirmation -->
            <password-field
              :is-confirmed="false"
              :is-validated="false"
              v-on:valid="validPassword = $event"
              v-on:input="passwordValue = $event"
            ></password-field>

            <!-- Stay Logged In Checkbox -->
            <auth-checkbox
              :label="'Stay logged in'"
              v-on:change="stayLoggedIn = $event"
            ></auth-checkbox>

            <!-- On Submit Validation Messages -->
            <login-errors
              :error="submissionError"
            >
            </login-errors>

            <!-- Form Submit Button -->
            <button
              class="
                button
                is-medium
                has-background-grey-lighter
                is-fullwidth
                has-text-weight-semibold
                has-text-white
                valid
              "
            >
              <span class="is-size-6">
                Log in with Email
              </span>
            </button>
          </form>
        </div>

        <!-- Conditions of Use -->
        <!-- we can add back the commented code once we have google captcha -->
        <!-- <conditions-of-use :isJoin="false"></conditions-of-use> -->

        <div class="has-text-centered">
          <!-- Navigate to Reset Password Form -->
          <router-link
            class="has-text-grey"
            :to="{ name: 'forgot-password', query: { email: emailValue } }"
          >Forgot Password?</router-link>

          <!-- Switch to Join Flow -->
          <!--
          <h3 class="is-size-6 has-text-weight-medium">
            Not a member?
            <router-link
              class="has-text-primary has-text-weight-black"
              :to="{ name: 'join' }"
            >Join Pouch</router-link>
          </h3>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import EmailField from './components/EmailField'
import AuthCheckbox from './components/AuthCheckbox'
import PasswordField from './components/PasswordField'
import LoginErrors from './LoginErrors.vue'

export default {
  components: { AuthCheckbox, EmailField, PasswordField, LoginErrors },

  data () {
    return {
      // User email validation
      emailValue: '',
      validEmail: false,

      // Password validation
      passwordValue: '',
      validPassword: false,

      // Authorized session should persist
      stayLoggedIn: false,

      // Form was submitted and failed
      submissionError: '',

      // Hardcoded message.
      shown: true,
      customMessages: [
        {
          id: 'confirmed_user',
          level: 'success',
          text: 'Your email has been confirmed'
        }
      ]
    }
  },

  methods: {
    ...mapActions('account/', ['login']),

    /**
     * Log user in on valid state and redirect to account.
     */
    async handleSubmit () {
      // if email has a value
      if (!this.emailValue) {
        this.submissionError = 'Please enter a valid email'
        return
      }
      // if email is in the form a@a.com
      if (!this.validEmail) {
        this.submissionError = 'Email is not valid'
        return
      }
      // if password has a value
      if (!this.passwordValue) {
        this.submissionError = 'Please enter a password'
        return
      }
      this.$emit('loading', true)
      await this.login({
        provider: 'email',
        credentials: {
          email: this.emailValue,
          password: this.passwordValue
        }
      })
        .then(() => this.$router.push({ name: 'pouch-points' }))
        .catch((e) => this.submissionError = e.message)
      this.$emit('loading', false)
    },

    sendSuccessMessage () {
      this.shown = false
      localStorage.setItem('userConfirmed', true)
      setTimeout(() => {
        this.shown = true
      }, 5000)
    },

    sendErrorMessage () {
      this.messages[0].level = 'danger'
      this.messages[0].text = 'Something went wrong. Please try to refresh the page. If it happens again'
      this.shown = false
      localStorage.setItem('userConfirmed', false)
      setTimeout(() => {
        this.shown = true
      }, 5000)
    }
  },

  created () {
    /**
     * Push success or error message based on
     * both url param and localStorage item values.
     */
    if (this.$route.query.confirmedUser === 'true') {
      if (!localStorage.getItem('userConfirmed')) return this.sendSuccessMessage()
      if (localStorage.getItem('userConfirmed') === 'false') return this.sendSuccessMessage()
    }

    if (this.$route.query.confirmedUser === 'false') {
      if (!localStorage.getItem('userConfirmed')) return this.sendErrorMessage()
      if (localStorage.getItem('userConfirmed') === 'false') return this.sendErrorMessage()
    }
  }

  // uncoment once we restore social login
  // mounted () {
  //   this.$messages.removeShown('auth')
  //   this.$emit('has-notification', false)
  // }
}
</script>

<style lang="sass" scoped>
.form-block
  padding: 10px 0
  @media (min-width: 768px)
    background: url(/static/images/auth_background.svg) no-repeat center 0
  &__wrapper
    margin: 62px auto 100px auto
    max-width: 464px
    @media (min-width: 768px)
      background-color: white
      padding: 32px 72px
  &__heading
    text-align: center
    margin-bottom: 24px

.form-block__wrapper h1
  margin-bottom: 20px
.conditions
  margin-bottom: 2rem

h3
  margin-top: 2rem

div > a
  text-decoration: underline
  font-size: 0.8rem

  &:hover
    color: #FF6441 !important

form > button
    margin: 0 0 1rem 0
    border-radius: 4px

    &.valid
      background: #FF6441 !important

      &:hover
        background: #FF785A !important
.auth-flash-message-container
  margin: 12px 0
.success
  border: 1px solid #589F5A
  color: #589F5A
.danger
  border: 1px solid #FF3223
  color: #363636

.auth-flash-message
  margin-left: auto
  margin-right: auto
  text-align: center
  width: 500px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 4px
  padding: 0 22px
  @media (max-width: 500px)
    width: 100%
  .nb-icon
    max-width: 10%
    margin: 0 5px
    min-width: 20px
    min-height: 20px
    img
      width: 20px
      height: 20px
      filter: invert(82%) sepia(100%) saturate(859%) hue-rotate(334deg) brightness(103%) contrast(101%)
  .nb-message
    margin: 12px 5px
    width: 100%
  .nb-action
    flex: 1
    justify-self: end
    img
      cursor: pointer
      min-width: 10px
      min-height: 10px
</style>
