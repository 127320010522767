<template>

  <div>

    <!-- Set the type as 'text' so toggle visiblity is possible -->
    <auth-input
      :limit=25
      :initial="''"
      :type="'text'"
      v-model="value"
      :hideable="true"
      v-on:input="onInput"
      :label="isConfirmed ? 'Change password' : 'Password'"
      :placeholder="isConfirmed ? 'Change password' : 'Password'"
    ></auth-input>

    <!-- Password Strength Validation -->
    <div v-if="isValidated" class="security">

      <!-- Indicator Bars Section -->
      <div class="bars columns is-mobile has-padding-2">

        <div
          v-for="i in 3"
          :key="i"
          :id="i + '-bar'"
          class="
            bars
            column
            is-paddingless
            has-margin-1
          "
          :class="`${
            // Set all bars green if all validations pass
            !!value && validated.every(Boolean) ? 'is-green'
              // Set two bars orange if some validations pass
              : !!value && validated.find(Boolean) && i < 3 ? 'is-orange'
              // Set first bar red if there is some input
              : !!value && i === 1 ? 'is-red'
              // Leave all bars grey
              : ''
          }`"
        ></div>

      </div>

      <!-- Validation Message Section -->
      <p
        v-for="(msg, i) in messages"
        :key="i"
        class="
          message
          is-size-7
          has-text-left
          has-text-grey-light
          has-text-weight-medium
        "
      >
        <!-- The validation message -->
        <span :class="`${validated[i] ? 'is-valid' : ''}`">{{ msg }}</span>
      </p>
    </div>

    <!-- Show Match Password Field if Confirmation is Required-->
    <div v-if="isConfirmed">

      <!-- Set the type as 'text' so toggle visiblity is possible -->
      <auth-input
        :limit=25
        :initial="''"
        :type="'text'"
        v-model="match"
        :hideable="true"
        v-on:input="onInput"
        :label="'Repeat password'"
        :placeholder="'Repeat password'"
      ></auth-input>

    </div>

  </div>

</template>

<script>
import AuthInput from './AuthInput'

export default {
  components: { AuthInput },

  props: {
    /**
     * Display the confirm password field
     */
    isConfirmed: Boolean,

    /**
     * Display the password strength validation UI
     */
    isValidated: Boolean
  },

  data: () => ({
    // Password and match values for validation
    value: '',
    match: '',

    /**
     * Password Strength Messages
     */
    messages: [
      'Upper and lowercase letters',
      'More than 8 characters',
      'Contains a number and a symbol'
    ],

    rules: [
      /**
       * The password must contain both lower- and upper-case letters
       */
      str => !!str && (/[a-z]+/.test(str) && /[A-Z]+/.test(str)),

      /**
       * The password must be at least nine characters in length
       */
      str => !!str && (str.length > 8),

      /**
       * The password must include some numerals and some symbol character
       */
      str => !!str && (/[0-9]+/.test(str) && /\W+/g.test(str))
    ],

    validated: [
      false,
      false,
      false
    ]
  }),

  methods: {
    /**
     * Repeat password is a match.
     */
    isMatch () {
      return Boolean(this.match && this.match === this.value)
    },

    /**
     * Test input validity.
     */
    isValid () {
      this.rules.forEach((rule, i) => {
        this.validated.splice(i, 1, rule(this.value))
      })

      return this.validated.every(Boolean)
    },

    /**
     * Handle the input event
     * TODO: tidy this flow control...
     */
    onInput () {
      this.$emit('empty', !this.value.length && !this.match.length)
      if (this.isConfirmed) {
        this.$emit('input', this.match)
        this.$emit('match', this.isMatch())
        this.$emit('valid', this.isValid())
        return
      }

      this.$emit('input', this.value)
      this.$emit('valid', this.isValid())
    }
  }
}
</script>

<style lang="sass" scoped>

div.columns.bars.is-mobile
  display: flex

div.columns.bars
  margin-bottom: 0

div.column.bars
  background: #DDDDDD
  height: 3px
  border-radius: 1px

  &.is-red
    background: rgba(255,0,0,0.6)

  &.is-orange
    background: rgba(255,100,65,0.8)

  &.is-green
    background: rgba(88,159,90,0.8)

div.security
  padding-bottom: 0.4rem

p.message
  background: transparent
  line-height: 1rem
  margin-bottom: 0.2rem

  & > span

    &::before
      content: ''
      width: 16px
      height: 8px
      margin-left: 0.2rem
      display: inline-block
      background: url(/static/images/auth_bullet.svg) no-repeat left bottom

    &.is-valid
      color: #363636

      &::before
        background: url(/static/images/auth_success.svg) no-repeat left bottom

</style>
